import React from "react";

// node_modules
import IconButton from "@material-ui/core/IconButton";
import {
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// Local modules
// import 'styles/components/StyledPasswordInput.css';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

export default function StyledPasswordInput(props) {
  const { height, width, borderRadius } = props;
  const useStyles = makeStyles({
    styledPasswordInput: {
      borderRadius,
      height,
      width,
    },
    root: {
      width: "100% !important",
    },
  });
  const classes = useStyles();

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    props.onChange(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      className={classes.root}
      variant="outlined"
    >
      <InputLabel htmlFor="outlined-adornment-password" id="labelPassword">
        Senha
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        className={classes.styledPasswordInput}
        type={values.showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange("password")}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={47}
      />
    </FormControl>
  );
}

StyledPasswordInput.defaultProps = {
  height: "55px",
  width: "100%",
  borderRadius: "50px",
  onChange: () => {},
};

StyledPasswordInput.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  onChange: PropTypes.func,
};
