import secrets from "./secrets";

export const getAccess = () => {
  return localStorage.getItem("user");
};

export const setLogin = (user, password) => {
  const { conuber, guest } = secrets.login;

  if (
    (user.toLowerCase() === conuber.user && password === conuber.password) ||
    (user.toLowerCase() === guest.user && password === guest.password)
  ) {
    localStorage.setItem("user", user.toLowerCase());
    return true;
  }

  return false;
};

export const setLogout = () => {
  localStorage.removeItem("user");
  return true;
};
