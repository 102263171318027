import React, { useState } from "react";

import {
  Avatar,
  Typography,
  Badge,
  IconButton,
  Collapse,
} from "@material-ui/core";
import {
  PowerSettingsNew as IconPowerOff,
  ArrowForwardIos as IconArrowRight,
  ArrowBackIos as IconArrowLeft,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    height: 55,
    padding: "0 5px",
    position: "fixed",
    zIndex: 9,
    display: "flex",
    alignItems: "center",
    borderBottomLeftRadius: 15,
    backgroundColor: "#ffffffd4",
    color: "#000",
    top: 0,
    right: 0,
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      backgroundColor: "#3a6ea2",
    },
    "& .MuiBadge-badge": {
      backgroundColor: "#03ff59",
      height: 15,
      minWidth: 15,
    },
    "& .MuiTypography-root ": {
      marginLeft: 10,
    },
    "& hr": {
      height: 30,
      width: "1px",
      backgroundColor: "#00000047",
      border: 0,
      borderRadius: 1,
      margin: "0 5px 0 15px",
    },
  },
  buttonExit: {
    color: "#e54c2f",
  },
  arroyFade: {
    position: "absolute",
    left: -40,
    color: "#fff",
  },
});

const PerfilInfo = ({ perfilName, setLogout }) => {
  const [showPerfil, setShowPerfil] = useState(true);

  const classes = useStyles();

  const handleShowPerfilClick = () => {
    if (showPerfil) {
      return setShowPerfil(false);
    }
    return setShowPerfil(true);
  };

  return (
    <div className={classes.root}>
      <IconButton
        color="primary"
        className={classes.arroyFade}
        onClick={() => {
          handleShowPerfilClick();
        }}
      >
        {showPerfil ? <IconArrowRight /> : <IconArrowLeft />}
      </IconButton>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent=" "
      >
        <Avatar>{perfilName && perfilName.substr(0, 1)}</Avatar>
      </Badge>
      <Collapse in={showPerfil} timeout={400} unmountOnExit>
        <Typography variant="caption" color="inherit">
          {perfilName && perfilName}
        </Typography>
        <hr />
        <IconButton
          className={classes.buttonExit}
          onClick={() => {
            setLogout();
          }}
        >
          <IconPowerOff />
        </IconButton>
      </Collapse>
    </div>
  );
};

export default PerfilInfo;
