import React, { useState, useEffect } from "react";

import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Event as EventIcon } from "@material-ui/icons";

import Footer from "../components/Footer";
import Schedule from "../components/Schedule";
import PerfilInfo from "../components/PerfilInfo";

import imageBrain from "../static/images/cerebro2.svg";
import imageTheme from "../static/images/mentesa.png";

import { getAccess, setLogout } from "../config/accessControl";

const Home = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [perfilName, setPerfilName] = useState(false);
  const [urlStreaming, setUrlStreaming] = useState(false);

  useEffect(() => {
    getPerfilName();
  });

  useEffect(() => {
    if (perfilName) {
      const url =
        perfilName.toLowerCase() === "conuber"
          ? "https://www.youtube.com/embed/pI5VChHPiqo"
          : "https://www.youtube.com/embed/pI5VChHPiqo";
      setTimeout(() => {
        setUrlStreaming(url);
      }, 1000);
    }
  }, [perfilName]);

  const getPerfilName = async () => {
    const perfil = await getAccess();
    setPerfilName(perfil);
  };

  const handleLogoutClick = async () => {
    await setLogout();
    window.location.reload(false);
  };

  return (
    <div className="home">
      <div className="div-background"></div>
      <PerfilInfo perfilName={perfilName} setLogout={handleLogoutClick} />
      <Schedule open={openDialog} setOpen={setOpenDialog} />
      <div className="main">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <img className="image-theme" src={imageTheme} alt="" />
            <Typography className="text-conube-day" variant="body1">
              ● Conube Day - 2020 ●
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className="header-video">
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={9}>
                  <div className="event-summary">
                    <Button
                    variant="contained"
                      color="default"
                      startIcon={<EventIcon fontSize="large" />}
                      // onClick={() => {
                      //   setOpenDialog(true);
                      // }}
                      onClick={() =>
                        window.open(
                          "https://www.figma.com/proto/VcNok3E6t5eAa78bOTA9Ro/Digital?node-id=512%3A12&viewport=245%2C651%2C0.362894207239151&scaling=scale-down-width",
                          "blank"
                        )
                      }
                    >
                      Cronograma, clique aqui!
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="div-image-brain">
                    <img src={imageBrain} alt="" />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="div-video">
              {urlStreaming ? (
                <iframe
                  title="Conube Day 2020 2"
                  width="100%"
                  height="100%"
                  src={urlStreaming}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  frameborder="0"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
              ) : (
                <CircularProgress />
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Alert severity="info">
                <Typography variant="body1">
                  Caso haja algum problema com a visualização da transmissão
                  <strong> limpe o cache e recarregue a página</strong>!.
                </Typography>
              </Alert>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
