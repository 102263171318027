import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { getAccess } from "./config/accessControl";

import Login from "./pages/Login";
import Home from "./pages/Home";
import LoadingPage from "./components/LoadingPage";

const MainRoutes = () => {
  const [accessAllowed, setAccessAllowed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAccessAllowed();
  });

  const getAccessAllowed = async () => {
    setAccessAllowed(await getAccess());
    setLoading(false);
  };
  return loading ? (
    <LoadingPage />
  ) : (
    <Routes>
      {accessAllowed ? (
        <Route path="/" element={<Home />} />
      ) : (
        <Route path="/" element={<Login />} />
      )}
    </Routes>
  );
};

export default MainRoutes;
