import React from "react";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";
import { Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

export default function TimeLineSchedule() {
  const useStyles = makeStyles({
    root: {
      "& .MuiTimeline-root": {
        padding: 0,
      },
      "& .MuiTimelineContent-root": {
        width: "500px",
      },
    },
  });
  const classes = useStyles();

  const schedule = [
    {
      time: "13:00hs",
      title: "Abertura Conube Day",
      details: "Equipe Conube Day",
    },
    {
      time: "13:30hs",
      title: "Palestra: Meditação com ação",
      details: "Marcus Rojo",
    },
    {
      time: "14:30hs",
      title: "Alongamento",
      details: "Lucas",
    },
    {
      time: "14:35hs",
      title: "1º Intervalo",
      details: "Apriveite para se hidratar! ;)",
    },
    {
      time: "14:45hs",
      title: "Palestra: Inteligência Emocional",
      details: "Tatiana Pimenta e Convidados",
    },
    {
      time: "15:45hs",
      title: "Desafio TikTok",
      details: "Equipe Conube Day + Conubers",
    },
    {
      time: "15:50hs",
      title: "2º Intervalo",
      details: "Recarregue suas energias!",
    },
    {
      time: "16:00hs",
      title: "Palestra: Garras sem arranhas",
      details: "Wesley Badi",
    },
    {
      time: "17:00hs",
      title: "3º Intervalo",
      details: "Não fique com saudaes!",
    },
    {
      time: "17:15hs",
      title: "Breve apresentação + Ganhadores do desafio TikTok",
      details: "Anderson Feitosa!",
    },

    {
      time: "17:45hs",
      title: "Encerramento",
      details: "",
    },
  ];

  return (
    <div className={classes.root}>
      <Timeline align="alternate">
        {schedule &&
          schedule.map((item) => (
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body1" color="textSecondary">
                  {item.time}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="body1" color="textPrimary">
                  {item.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {item.details}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </div>
  );
}
