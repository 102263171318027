import React, { forwardRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Close as IconClose, Event as EventIcon } from "@material-ui/icons";

import TimeLineSchedule from "./TimeLineSchedule";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Schedule(props) {
  const { open, setOpen } = props;

  const useStyles = makeStyles({
    root: {
      "& .MuiDialog-paper": {
        background: "#3e64ac",
      },
      "& .MuiDialogTitle-root": {
        marginTop: 35,
        "& .MuiTypography-root": {
          color: "#fff !important",
        },
        "& .MuiButtonBase-root": {
          position: "absolute",
          top: 40,
          right: 10,
        },
        "& .MuiSvgIcon-root": {
          float: "left",
          marginRight: 10,
        },
      },
      "& .MuiDialogContent-root": {
        background: "#fff",
        borderTopLeftRadius: 50,
        padding: "40px 0 20px 0",
      },
      "& .MuiDialogActions-root": {
        background: "#fff",
      },
      "@media (max-width: 600px)": {
        "& .MuiDialog-paper": {
          width: "100vw",
          margin: 0,
          height: "100vh",
          maxHeight: "none",
          borderRadius: "0",
        },
      },
    },
  });
  const classes = useStyles();

  return (
    <Dialog
      className={classes.root}
      TransitionComponent={Transition}
      keepMounted
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <EventIcon fontSize="large" />
        <Typography variant="h5">Cronograma</Typography>
        <IconButton
          color="inherit"
          onClick={() => {
            setOpen(false);
          }}
        >
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TimeLineSchedule />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          autoFocus
          onClick={() => {
            setOpen(false);
          }}
        >
          Vamos lá!
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Schedule.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
