import React from "react";

import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import imageConube from "../static/images/conube.svg";
// import imageFacebook from "../static/images/facebook.png";
// import imageInstagram from "../static/images/instagram.png";
// import imageSpotify from "../static/images/spotify.png";

export default function LoadingPage() {
  const useStyles = makeStyles({
    root: {
      "& .MuiCircularProgress-root": {
        width: '65px !important',
        height: '65px !important',
        display: "block",
        margin: "50% auto 0 auto",
        color: "#fff",
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
}
