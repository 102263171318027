import React, { useState } from "react";

import { setLogin } from "../config/accessControl";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import StyledPasswordInput from "../components/StyledPasswordInput";
import StyledTextField from "../components/StyledTextField";

import imageBrain from "../static/images/cerebro.svg";
import imageTheme from "../static/images/mentesa.png";

const Login = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);

  const handleLoginClick = async () => {
    const access = await setLogin(user, password);
    if (access) {
      window.location.reload(false);
    } else {
      setLoginError("O login informado está errado, verifique!");
    }
  };
  return (
    <div className="login">
      <div className="div-background"></div>
      <Grid
        style={{ height: "100vh" }}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Container maxWidth="sm">
            <div className="div-images">
              <img className="image-1" src={imageBrain} alt="" />
              <img
                className="image-2"
                src={imageTheme}
                alt=""
              />
            </div>
            <Card className="card-login">
              <CardContent>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={10}>
                    <Typography variant="h5" align="center">
                      Utilize o login fornecido
                    </Typography>
                    <Typography variant="body1" align="center">
                      Para acessar a transmissão do Conube Day 2020 é necessário
                      inserir os dados do login fornecidos pelo comitê.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                {loginError && (
                  <Typography className="message-error" color="error">
                    {loginError}
                  </Typography>
                )}
                <form
                  method="post"
                  onSubmit={(event) => event.preventDefault()}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={6}
                  >
                    <Grid item xs={12}>
                      <StyledTextField
                        label="Login"
                        type="text"
                        value={user}
                        onChange={(event) => setUser(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledPasswordInput
                        width="100%"
                        value={password}
                        onChange={setPassword}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Button
                          style={{ float: "right", width: "200px" }}
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={handleLoginClick}
                        >
                          Acessar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>

            <Typography
              variant="h5"
              style={{ color: "#fff", marginTop: "20px", textAlign: "center" }}
            >
              Conube Day - 2020
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
