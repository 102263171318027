import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      contained: {
        padding: "10px 25px",
        "&.Mui-disabled": {
          color: "#fff",
        },
      },
      outlined: {
        padding: "10px 25px",
      },
      text: {
        padding: "10px 25px",
      },
    },
    MuiLinearProgress: {
      root: {
        height: 5,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: "#cccbd2",
      },
      bar: {
        borderRadius: 5,
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 15,
        boxShadow:
          "0px 0px 0px -3px rgba(0,0,0,0.2), 0px 5px 5px 1px rgba(0,0,0,0.14), 0px 0px 5px 0px rgba(0,0,0,0.12)",
      },
    },
    MuiInputBase: {
      root: {
        paddingLeft: 10,
        paddingRight: 10,

        "& .MuiSelect-root:focus": {
          backgroundColor: "rgba(0,0,0,0)",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(24px, 20px) scale(1)",
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 15,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 15,
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 15,
      },
    },
    MuiAutocomplete: {
      input: {
        paddingLeft: "15px !important",
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: "bold",
      },
    },
    MuiLink: {
      root: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#3e64ac",
      lightest: "#CBC0F0",
      alpha: "rgba(87, 61, 168, 0.25)",
    },
    secondary: {
      main: "#f7bf12",
      lightest: "#FFEBDB",
      contrastText: "#ffffff",
    },
    error: {
      main: "#d62046",
      lightest: "#FFD1DA",
      alpha: "rgba(214, 32, 70, 0.25)",
    },
    warning: {
      main: "#f17700",
      lightest: "#FFEBDB",
      alpha: "rgba(241, 119, 0, 0.25)",
    },
    info: {
      main: "#44a0db",
      lightest: "#BCE5FF",
      alpha: "rgba(68, 160, 219, 0.2)",
    },
    success: {
      main: "#48ae9f",
      lightest: "#B5E2DB",
      alpha: "rgba(172, 221, 216, 0.5)",
    },
    text: {
      primary: "#383743",
      lightest: "#C9C9C9",
    },
    danger: {
      main: "#000",
    },
    white: {
      main: "#F4F3F1",
      almost: "#F7F7FA",
    },
    action: {
      disabledBackground: "#cccbd2",
    },
    midGrey: {
      main: "#66647A",
    },
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: 50,
  },
  typography: {
    fontFamily: ["Nunito"].join(","),
    h1: {
      fontSize: 69,
      fontWeight: "bold",
      color: "#383743",
    },
    h2: {
      fontSize: 53,
      fontWeight: 300,
      color: "#383743",
    },
    h3: {
      fontSize: 40,
      fontWeight: 300,
      color: "#383743",
    },
    h4: {
      fontSize: 31,
      color: "#383743",
    },
    h5: {
      fontSize: 24,
      color: "#383743",
    },
    h6: {
      fontSize: 20,
      fontWeight: 400,
      color: "#383743",
    },
    subtitle1: {
      fontSize: 16,
      letterSpacing: 1.04,
      lineHeight: 1.25,
    },
    subtitle2: {
      fontSize: 13,
      letterSpacing: 0.58,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.51,
      color: "#66647a",
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.51,
      fontWeight: 300,
      color: "#383743",
    },
    button: {
      fontSize: 14,
      letterSpacing: 0.6,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: 14,
      fontWeight: 300,
      textTransform: "uppercase",
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      textTransform: "uppercase",
      lineHeight: 2,
      marginTop: "10px",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
