import React from "react";

import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import imageConube from "../static/images/conube.svg";
import imageFacebook from "../static/images/facebook.png";
import imageInstagram from "../static/images/instagram.png";
import imageSpotify from "../static/images/spotify.png";

export default function Footer() {
  const useStyles = makeStyles({
    root: {
      borderTopRightRadius: "15px",
      borderTopLeftRadius: "15px",
      marginTop: "80px",
      backgroundColor: "#fff",
      padding: "20px",
      minHeight: "120px",
      "& .div-social": {
        float: "right",
        "& a": {
          marginLeft: "15px",
        },
        "& img": {
          width: "40px",
        },
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <div className="div-social">
            <a
              href="https://open.spotify.com/playlist/0AXkkJkj4t55b4l0iQhPSt"
              target="_blank"
              rel = "noopener noreferrer"
            >
              <img src={imageSpotify} alt="" />
            </a>
            <a
              href="https://www.instagram.com/conubecontabilidade/"
              target="_blank"
              rel = "noopener noreferrer"
            >
              <img src={imageInstagram} alt="" />
            </a>
            <a
              href="https://web.facebook.com/conube/?_rdc=1&_rdr"
              target="_blank"
              rel = "noopener noreferrer"
            >
              <img src={imageFacebook} alt="" />
            </a>
          </div>
          <img className="logo-conube" src={imageConube} alt="" />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ textAlign: "center" }}>
            © Todos direitos reservados
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
