module.exports = {
  login: {
    conuber: {
      user: "conuber",
      password: "ConubedayConuber@2020",
    },
    guest: {
      user: "convidado",
      password: "Conubeday@2020",
    },
  },
};
